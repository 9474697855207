import React from "react"
import styled, { css } from "styled-components"
import { darken, lighten } from "polished"
import { applyStyleModifiers } from "styled-components-modifiers"

import { Wrapper, Type, Color, Media } from "../../utilities"

const MODIFIER_CONFIG = {
  light: () => css`
    background-color: ${Color.light_grey};
    h1,
    h2 {
      color: ${Color.blue};
    }

    h3 {
      color: ${Color.grey};
    }

    .cap {
      &::before {
        background-color: ${Color.light_grey};
      }
    }
  `,
  grey: () => css`
    color: ${Color.white};
    background-color: ${Color.grey};
    h1,
    h2 {
      color: ${Color.white};
    }

    a {
      color: ${lighten(0.3, Color.blue)};
    }

    h3 {
      color: ${lighten(0.3, Color.blue)};
    }

    .cap {
      &::before {
        background-color: ${Color.grey};
      }
    }
  `,
  rounded: () => css`
    position: relative;
    padding-top: 120px;

    .cap {
      position: absolute;
      top: 0;
      left: 0;
      height: 120px;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      background-color: ${Color.white};

      &::before {
        content: "";
        border-radius: 50%;
        display: block;
        width: 100%;
        height: 140px;
        margin-top: 50px;
      }
    }
  `,
}

const GRID_CONFIG = {
  third1x2: () => css`
    grid-template-columns: 1fr 2fr;
    ${Media.below.tablet`
    grid-template-columns: 1fr;
    `}
  `,
  third2x1: () => css`
    grid-template-columns: 2fr 1fr;
    ${Media.below.tablet`
    grid-template-columns: 1fr;
    `}
  `,
}

const StyledSection = styled.section`
  padding: 4em 1em;
  background-color: ${Color.white};
  overflow: hidden;
  line-height: 150%;

  a {
    text-decoration: none;
    font-weight: bold;
    color: ${Color.blue};

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-family: ${Type.header};

    ${Media.below.mobile`
      line-height: 100%;
    `}
  }

  h1,
  h2 {
    color: ${darken(0.2, Color.light_grey)};
  }

  h3 {
    color: ${Color.blue};
    margin-bottom: 2rem;
  }

  h4,
  h5,
  h6,
  h7,
  h8 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  ul {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }

  ${Media.below.tablet`
    h2 {
      font-size: 2.8rem;
    }
  `}

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}

  ${applyStyleModifiers(GRID_CONFIG)}
`

export const Section = ({ children, ...props }) => (
  <StyledSection {...props}>
    <Wrapper>{children}</Wrapper>
  </StyledSection>
)

export const RoundedSection = ({ children, modifiers, ...props }) => (
  <StyledSection modifiers={["rounded", ...modifiers]} {...props}>
    <div className="cap" />
    <Wrapper>{children}</Wrapper>
  </StyledSection>
)
