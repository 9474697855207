import styled, { css } from "styled-components"
import { Link } from "react-scroll"
import { darken, lighten } from "polished"

import { applyStyleModifiers } from "styled-components-modifiers"

import { Color, Type } from "../../utilities"

const MODIFIER_CONFIG = {
  blue: () => css`
    background-color: ${Color.blue};
    color: ${Color.white} !important;

    &:hover {
      background-color: ${Color.white};
    }
  `,

  grey: () => css`
    background-color: ${Color.grey};
    color: ${Color.white} !important;

    &:hover {
      background-color: ${lighten(0.2, Color.grey)};
    }
  `,

  white: () => css`
    background: ${Color.white};
    color: ${Color.grey} !important;

    &:hover {
      background-color: ${darken(0.2, Color.white)};
    }
  `,
  block: () => css`
    width: 100%;
  `,
}

const buttonCSS = css`
  border: 0;
  padding: 0.5rem 1.5rem;
  margin: 0;
  text-decoration: none;
  font-family: ${Type.header};
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  margin-top: ${({ marginTop }) => marginTop || "0"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};
  margin-left: ${({ marginLeft }) => marginLeft || "0"};
  margin-right: ${({ marginRight }) => marginRight || "0"};

  ${applyStyleModifiers(MODIFIER_CONFIG)}
`

export const Button = styled(Link)`
  ${buttonCSS}
  text-align: center;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
`

export const ButtonHTMLLink = styled.a`
  ${buttonCSS}
  text-decoration: none !important;
  display: inline-block;
  text-align: center;
`
