import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

import * as moment from "moment"

import { Color, Wrapper, Flex } from "../../utilities"

const StyledFooter = styled.div`
  background-color: ${Color.black};
  color: ${Color.white};
  padding: 2rem 0 4rem 0;
  font-size: 0.9rem;
  line-height: 200%;

  a {
    text-decoration: none;
    color: ${Color.white};
    &:hover {
      text-decoration: underline;
      color: ${Color.white};
    }
  }
`

const SocialIcons = styled.div`
  text-align: right;
  a {
    font-size: 1.5rem;
    margin-right: 2rem;
  }
`

const Footer = ({ siteMetadata = {} }) => {
  const { social } = siteMetadata
  return (
    <StyledFooter>
      <Wrapper>
        <Flex>
          <div>
            &copy;{moment().year()} {siteMetadata.title}
          </div>
          <SocialIcons>
            <a target="_blank" href={social.facebook}>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a target="_blank" href={social.twitter}>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a target="_blank" href={social.linkedin}>
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </SocialIcons>
        </Flex>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
