import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"
import { lighten } from "polished"

import { Color, Media, Type } from "../../utilities"

const StyledNavbar = styled.nav`
  a {
    text-decoration: none;
    color: ${Color.grey};
    font-family: ${Type.header};
    margin-right: 0.5rem;
    padding: 0.8rem 1.75rem;
    font-size: 0.9rem;
    border-bottom: 5px solid ${Color.white};
    transition: all 0.2s ease-in-out;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: ${Color.blue};
      border-bottom-color: ${Color.blue};
    }

    &.contact {
      background-color: ${Color.blue};
      color: ${Color.white};
      border-bottom-color: ${Color.blue};

      &:hover {
        border-bottom-color: ${lighten(0.3, Color.blue)};
        background-color: ${lighten(0.3, Color.blue)};
      }
    }

    ${Media.below.desktop`
      padding: 0.8rem 1rem;
      font-size: 0.8rem;
      margin-right: 0;
    `}
  }

  ${Media.below.tablet`
    display: flex;
    height: 100%;
    align-content: center;
    align-items: center;

    a {
      text-align: center;
      align-items: center;
      align-content: center;
      font-size: 0.9rem;
    }  
  `}

  ${Media.below.mobile`
    display: none;
    background: ${Color.white};
    
    a {
      display: block;
      width: 100%;
      box-sizing: border-box;
      background-color: ${Color.white};
    }

    &.active {
      display: block;
    }
  `}
`

export const LinkSmooth = ({ children, name, ...props }) => (
  <Link to={name} smooth={true} offset={-20} {...props}>
    {children}
  </Link>
)

const NavBar = ({ closeMenu, ...props }) => (
  <StyledNavbar {...props}>
    <LinkSmooth name="person">The Person</LinkSmooth>
    <LinkSmooth name="relationships">The Passion</LinkSmooth>
    <LinkSmooth name="help">The Professional</LinkSmooth>
    <LinkSmooth name="public">The Public Speaker</LinkSmooth>
    <LinkSmooth name="contact" className="contact">
      Contact
    </LinkSmooth>
  </StyledNavbar>
)

export default NavBar
