import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

import { Color, Type, Media } from "../../utilities"

export const GlobalStyles = createGlobalStyle`
  ${normalize()};

  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

  body {
      background-color: ${Color.black};
      font-family: ${Type.text};
      font-size: ${Type.basesize};
      color: ${Color.grey};

      ${Media.below.tablet`
        font-size: ${Type.mobilebasesize};
      `}
  }

  p {
      margin: 0;
  }

  h1, h2, h3, h4, h5, h6, h7, h8, h9 {
      margin: 0;
      padding: 0;
      font-family: ${Type.header};
      margin-bottom: 1rem;
  }

  h1 {
      font-size: 4rem;
  }

  h2 {
      font-size: 3.5rem;
  }

  h3 {
      font-size: 1.75rem;
  }

  h4 {
      font-size: 1.5rem;
  }

  .text {
      &-center {
          text-align: center;
      }
      &-left {
          text-align: left;
      }
      &-right {
          text-align: right;
      }
  }

  a {
      cursor: pointer;
  }

  .orange {
    color: ${Color.blue};
  }

  .blue {
    color: ${Color.blue};
  }

  ${Media.below.mobile`
      .m-hidden {
          display: none;
      }
  `}

  ${Media.below.tablet`
      .mt-hidden {
          display: none;
      }
  `}

  
  ${Media.above.tablet`
      ${Media.below.desktop`
          .t-hidden {
              display: none;
          }
      `}
  `}
`
