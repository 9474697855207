import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import styled from "styled-components"

import { Color, Wrapper, Media } from "../../utilities"
import Navbar from "../Navbar"

import logo from "../../../images/logo.png"
import { Button } from "../../elements"

const height = 90

const StyledHeader = styled.header`
  background: ${Color.white};
  padding: 0;
  color: ${Color.grey};
  height: ${height}px;
  border-top: 10px solid ${Color.grey};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  ${Media.below.mobile`
    height: auto;
  `}
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;

  .logo {
    max-width: 112px;
    transition: all 0.1s ease-in-out;
  }

  ${Media.below.mobile`
    display: block;
    text-align: center;
  `}
`

const SocialIcons = styled.div`
  font-size: 1.5rem;
  color: ${Color.grey};
  text-align: right;

  a {
    color: ${Color.grey};
    display: inline-block;
    margin-right: 1.5rem;

    &:hover {
      color: ${Color.blue};
    }
  }
  ${Media.below.tablet`
    display: none;
  `}
`

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const MenuButton = styled(Button)`
  display: none;
  padding: 1rem 0.5rem;

  span {
    margin-left: 0.5rem;
  }

  &:hover {
    transform: none;
  }
`

const Header = ({ siteMetadata = {} }) => {
  const { social } = siteMetadata
  const [active, setActive] = useState(false)
  return (
    <>
      <StyledHeader>
        <StyledWrapper>
          <Link to="/">
            <img src={logo} alt="Jason Lowder" className="logo" />
          </Link>
          <HeaderRight>
            <SocialIcons>
              <a target="_blank" href={social.facebook}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a target="_blank" href={social.twitter}>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a target="_blank" href={social.linkedin}>
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </SocialIcons>
            <MenuButton
              modifiers={["grey", "block"]}
              onClick={() => setActive(!active)}
            >
              <FontAwesomeIcon icon={active ? faTimes : faBars} />
              <span>
                {active ? "Close " : "Open "}
                Navigation
              </span>
            </MenuButton>
            <Navbar
              className={active && "active"}
              closeMenu={() => setActive(!active)}
            />
          </HeaderRight>
        </StyledWrapper>
      </StyledHeader>
    </>
  )
}

export default Header
